

export const UploadformDisabled = ({...items}) => {
    return (
        <>
            <h1>Information om arbete i Måleriportalen</h1>
            <p>Vi ser just nu över viss funktionalitet i Måleriportalen varför inrapportering av löner inte kan göras. Anställning av lärlingar fungerar som vanligt.</p>
            <p>Vi återkommer med information via Måleriföretagen och Måleriportalen när inrapportering kan ske igen.</p>
            <p>Vid frågor kontakta supporten.</p>
        </>
    )
}